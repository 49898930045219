import { forwardRef } from 'react';
import {
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/react';

import Tooltip from 'components/ui/Tooltip';

import type { Option } from 'types/types';

import { ReactComponent as ChevronDownIcon } from 'assets/icons/chevron-down.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';

type Props = {
  className?: string;
  options: Option[];
  placeholder: string;
  label: string;
  tooltip?: string;
  hint?: string;
  value: string | number | null;
  onChange: (option: Props['value']) => any;
};

const Select = forwardRef<HTMLDivElement, Props>(
  (
    { className, options, placeholder, label, tooltip, hint, value, onChange },
    ref
  ) => {
    const selectedOption = options.find((el) => el.value === value) || null;

    return (
      <div ref={ref} className={className}>
        <Listbox
          as="div"
          value={selectedOption}
          onChange={(option) => {
            onChange(option?.value || null);
          }}
        >
          <Label className="flex items-center font-bold mb-0.5">
            {label}
            {tooltip && (
              <Tooltip title={tooltip}>
                <InfoIcon className="ml-1 size-5 cursor-pointer" />
              </Tooltip>
            )}
          </Label>
          <div className="relative">
            <ListboxButton className="relative w-full border border-border cursor-pointer rounded bg-background px-3 py-[11px] pr-10 text-left text-dark data-[open]:ring-2 data-[open]:ring-primary focus:outline-none focus:ring-2 focus:ring-primary">
              <span className="block truncate">
                {selectedOption?.name || placeholder}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronDownIcon className="size-6 text-dark" />
              </span>
            </ListboxButton>

            <ListboxOptions
              transition
              className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-background py-1 text-base shadow-lg border border-border-light focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
            >
              {options.map((option, i) => (
                <ListboxOption
                  key={i}
                  value={option}
                  className="group relative cursor-pointer select-none py-2 pl-3 pr-9 text-dark data-[focus]:bg-primary data-[focus]:text-dark"
                >
                  <span className="block truncate font-normal group-data-[selected]:font-bold">
                    {option.name}
                  </span>

                  <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-primary group-data-[focus]:text-dark [.group:not([data-selected])_&]:hidden">
                    <CheckIcon className="size-5" />
                  </span>
                </ListboxOption>
              ))}
            </ListboxOptions>

            {hint && <p className="mt-0.5 text-sm text-border">{hint}</p>}
          </div>
        </Listbox>
      </div>
    );
  }
);

export default Select;
