import { useMemo } from 'react';

import Headline from 'components/ui/Headline';
import PriceRange from 'components/PriceRange';
import Button from 'components/ui/Button';

import { useCmsData } from 'contexts/CmsDataProvider';
import { getOptionsFromAttributes } from 'utils/utils';

import type { VehicleResults } from 'types/types';

import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';

type Props = {
  vehicle: VehicleResults['all'][number];
  attributes: any;
  submissionLink: string;
  estimationPrice: { min: number; max: number };
  onEdit: () => any;
  showEstimationPriceDescModal: () => any;
};

const FinalView: React.FC<Props> = ({
  vehicle,
  attributes,
  estimationPrice,
  onEdit,
  showEstimationPriceDescModal,
  submissionLink,
}) => {
  const { data: cmsData, t, locale } = useCmsData();

  const { brand, model, type, bodyType } = vehicle;

  const advantages: string[] = useMemo(
    () =>
      cmsData?.directsaleTextStrings
        .filter(
          (el: any) => el.key === 'final_view_estimation_price_advantages_item'
        )
        .map((el: any) => el[locale]),
    [cmsData, locale]
  );
  const nextSteps: string[] = useMemo(
    () =>
      cmsData?.directsaleTextStrings
        .filter(
          (el: any) => el.key === 'final_view_estimation_price_next_steps_item'
        )
        .map((el: any) => el[locale]),
    [cmsData, locale]
  );

  return (
    <div className="px-6">
      <Headline
        className="mb-6"
        content={t('directsaleTextStrings', 'final_view_estimation_title')}
      />

      <div className="flex justify-between">
        <h3 className="truncate">
          {brand} {model} {type}{' '}
          {getOptionsFromAttributes('bodyType', bodyType, locale, attributes)}
        </h3>
        <span
          className="ml-3 text-dark underline cursor-pointer"
          onClick={onEdit}
        >
          {t('directsaleTextStrings', 'final_view_estimation_edit_vehicle_btn')}
        </span>
      </div>

      <div className="mt-6">
        <PriceRange
          min={estimationPrice.min}
          max={estimationPrice.max}
          hint={t(
            'directsaleTextStrings',
            'final_view_estimation_price_range_hint'
          )}
          onHintClick={showEstimationPriceDescModal}
        />

        <Button
          href={submissionLink}
          className="mt-6 w-full"
          label={t('directsaleTextStrings', 'success_button')}
        />

        <Headline
          className="mt-12"
          content={t(
            'directsaleTextStrings',
            'final_view_estimation_price_advantages_title'
          )}
        />
        <div className="mt-4">
          {advantages.map((advantage, i) => (
            <div key={i} className="flex items-start mb-3 last:mb-0">
              <CheckIcon className="mr-2 size-6 text-success" />
              {advantage}
            </div>
          ))}
        </div>
        <hr className="my-8 text-light" />
        <Headline
          className="mt-12"
          content={t(
            'directsaleTextStrings',
            'final_view_estimation_price_next_steps_title'
          )}
        />
        <div className="mt-4">
          {nextSteps.map((nextStep, i) => (
            <div key={i} className="flex items-start mb-3 last:mb-0">
              <div className="mr-2 flex items-center justify-center text-sm font-bold size-6 rounded-full bg-primary">
                {i + 1}
              </div>
              {nextStep}
            </div>
          ))}
        </div>

        <Button
          href={submissionLink}
          className="mt-6 w-full"
          label={t('directsaleTextStrings', 'success_button')}
        />
      </div>
    </div>
  );
};

export default FinalView;
